import React, { ReactNode } from "react";
import translate from "../../utils/translations/Translations";
import { withRouter } from "../../hoc/withRouter";

interface MusicKitProviderProps {
  children: ReactNode;
}

interface MusicKitProviderState {
  ready: boolean;
  error: boolean;
}

class MusicKitProvider extends React.Component<
  MusicKitProviderProps & { searchParams: any },
  MusicKitProviderState
> {
  constructor(props: MusicKitProviderProps & { searchParams: any }) {
    super(props);

    this.state = {
      ready: false,
      error: false,
    };
  }

  public componentDidMount() {
    const configure = async () => {
      const developerToken = this.props.searchParams.get("developerToken");
      try {
        await MusicKit.configure({
          developerToken,
          // developerToken:
          //   "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjZLODM3SFdNRDIifQ.eyJpYXQiOjE2Nzc1ODM0NDEsImV4cCI6MTY3Nzc4MzQ0MSwiaXNzIjoiMjRFN1ZINDM0NyJ9.-2_bDnWwBtFvZYKZkFHfzbQyhHwH7fmuM1XSg2hj8BeVbyRpUwUCM9bW2bdSOpXqtSKRFP1EVsDIrNERKiXQyQ",
          app: {
            name: "Hot 100",
            build: "1.0.0",
          },
        });

        // this.setLanguage(MusicKit.getInstance().storekit.storefrontCountryCode);

        const handler = (e: any) => {
          this.setLanguage(e.storefrontCountryCode);
        };

        MusicKit.getInstance().addEventListener(
          "storefrontCountryCodeDidChange",
          handler as () => void
        );

        this.setState({
          ready: true,
        });
      } catch (e) {
        console.log(e);
        this.setState({ error: true });
      }
    };

    configure();
  }

  public setLanguage = (countryCode: string) => {
    translate.setLanguage(countryCode);
    this.forceUpdate();
  };

  public render() {
    if (this.state.error) {
      return (
        <span>
          There was an error connecting to apple music, please try again later.
        </span>
      );
    }
    if (!this.state.ready) {
      return <span>Loading...</span>;
    }

    return this.props.children;
  }
}

export default withRouter(MusicKitProvider);
