import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import MusicKitProvider from "./components/Providers/MusicKitProvider";
import PlayerPage from "./screens/PlayerPage";
import AuthPage from "./screens/AuthPage";
import SmallPlayerPage from "./screens/SmallPlayerPage";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      document.addEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    }
  }, []);

  return (
    <Router>
      <MusicKitProvider>
        <Routes>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/player" element={<PlayerPage />} />
          <Route path="/small-player" element={<SmallPlayerPage />} />
          <Route path="/" element={<PlayerPage />} />
        </Routes>
      </MusicKitProvider>
    </Router>
  );
}

export default App;
