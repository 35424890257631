import React, { useEffect, useMemo, useState } from "react";
import { createMediaItem } from "../../utils/Utils";
import { FaPause, FaPlay } from "react-icons/fa";
import styled from "styled-components";
import { getSongData } from "../../services/MusicApi";
import withMK from "../../hoc/withMK";
import PlayerTime from "../PlayerTime";
// import VolumeControl from "../VolumeControl";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vw;
  padding-top: 10vw;
`;

const Image = styled.img`
  width: 80vw;
  height: 80vw;
  object-fit: cover;
`;

const Title = styled.div`
  font-size: 5vw;
  font-weight: 900;
  text-align: center;
  width: 80vw;
  margin-top: 5vw;
`;

const Subtitle = styled.div`
  font-size: 3.5vw;
  text-align: center;
  font-weight: 600;
  color: #fa2d47;
  width: 80vw;
  margin-top: 3.5vw;
  margin-bottom: 5vw;
`;

const MusicPlayer: React.FC<{}> = ({ mk }: any) => {
  const nowPlayingItem = mk.mediaItem && mk.mediaItem.item;
  const [fullSong, setFullSong] = useState<any>();
  const [playing, setPlaying] = useState(false);
  const [started, setStarted] = useState(false);

  const [searchParams] = useSearchParams();
  const musicId = searchParams.get("musicId");

  //   const song = "1670245868";
  const song = musicId;

  const playSong = () => {
    const play = async () => {
      try {
        const music = mk.instance;
        if (started) {
          await music.player.play();
        } else {
          await music.setQueue({
            // previewOnly: true,
            // @ts-ignore it works ¯\_(ツ)_/¯
            items: [createMediaItem(fullSong)],
          });
          await music.player.play();
          setStarted(true);
        }
      } catch (e) {
        alert(e);
      }
    };
    play();
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getSongData(song || "");
      setFullSong(data.data[0]);
    };

    if (song) {
      getData();
    }
  }, [song]);

  useEffect(() => {
    setPlaying(mk.instance.isPlaying);
  }, [mk]);

  const artwork = useMemo(() => {
    if (fullSong) {
      return MusicKit.formatArtworkURL(fullSong?.attributes?.artwork, 400, 400);
    }
  }, [fullSong]);

  return (
    <Container>
      <Image src={artwork} />
      <PlayerTime nowPlayingItem={nowPlayingItem} />

      <Title>{fullSong?.attributes.name}</Title>
      {!!fullSong?.attributes?.artistName && (
        <Subtitle>{`${fullSong?.attributes?.artistName} - ${fullSong?.attributes?.albumName}`}</Subtitle>
      )}

      {playing ? (
        <FaPause
          onClick={() => mk.instance.pause()}
          style={{ width: "10vw", cursor: "pointer", height: "10vw" }}
        />
      ) : (
        <FaPlay
          onClick={playSong}
          style={{ width: "10vw", cursor: "pointer", height: "10vw" }}
        />
      )}
      {/* <VolumeControl /> */}
    </Container>
  );
};
const bindings = {
  [MusicKit.Events.mediaItemDidChange]: "mediaItem",
  [MusicKit.Events.queueItemsDidChange]: "queueItems",
  [MusicKit.Events.queuePositionDidChange]: "queuePosition",
  [MusicKit.Events.playbackStateDidChange]: "playbackState",
};

export default withMK(MusicPlayer, bindings);
