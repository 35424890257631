/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaApple } from "react-icons/fa";
import withMK from "../hoc/withMK";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const AppleButton = styled.button`
  width: 80%;
  color: white;
  background-color: #fa2d47;
  padding: 5vw;
  font-weight: 600;
  margin: 3vw 0px;
  border-radius: 3vw;
  border: 0px;
  font-size: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppleButtonLink = styled.button`
  width: 80%;
  color: #fa2d47;
  background-color: transparent;
  padding: 5vw;
  font-weight: 600;
  margin: 3vw 0px;
  border-radius: 3vw;
  border: 0px;
  font-size: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AuthPage: React.FC<{}> = ({ mk }: any) => {
  const [initialized, setInitialized] = useState(false);
  const sendRNMessage = (messageObject: object) => {
    (window as any)?.ReactNativeWebView?.postMessage(
      JSON.stringify(messageObject)
    );
  };

  const handleAuth = async () => {
    try {
      // await mk.instance.unauthorize();
      sendRNMessage({ message: "PROCEED_WITH_CONNECTION" });
      const res = await mk.instance.authorize();
      sendRNMessage({ message: "CONNECTION_SUCCEED", token: res });
    } catch (e) {
      console.log(e);
      // Show subscribe requiired error here
      sendRNMessage({ message: "NO_ACTIVE_SUBSCRIPTION" });
      alert(
        "in order to use apple music you need to have an active subscription on your account"
      );
    }
  };

  useEffect(() => {
    if (mk.instance.isAuthorized && initialized) {
      sendRNMessage({
        message: "CONNECTION_SUCCEED",
        token: mk.instance.musicUserToken,
      });
    }
  }, [mk.instance.isAuthorized, initialized]);

  useEffect(() => {
    const handleAddQueryParameter = () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.set("alreadyInitialized", "true");

      // Update the URL with the new query parameter
      url.search = params.toString();
      window.history.replaceState(null, "", url.toString());
    };

    const handleUnauth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const alreadyInitialized = urlParams.get("alreadyInitialized");

      if (!initialized) {
        if (alreadyInitialized !== "true") {
          await mk.instance.unauthorize();
          handleAddQueryParameter();
        }
        setInitialized(true);
      }
    };

    handleUnauth();
  }, [initialized]);

  return (
    <Container>
      {mk.instance.isAuthorized ? (
        <AppleButton
          onClick={() => {
            mk.instance.unauthorize();
          }}
        >
          Sign Out
        </AppleButton>
      ) : (
        <AppleButton onClick={handleAuth}>
          <FaApple style={{ marginRight: "1vw" }} /> Sign In with Apple
        </AppleButton>
      )}
      <AppleButtonLink
        onClick={() => {
          sendRNMessage({ message: "CANCEL" });
        }}
      >
        Cancel
      </AppleButtonLink>
    </Container>
  );
};

const bindings = {
  [MusicKit.Events.authorizationStatusDidChange]:
    "authorizationStatusDidChange",
  [MusicKit.Events.eligibleForSubscribeView]: "eligibleForSubscribeView",
};

export default withMK(AuthPage, bindings);
